








import Vue from "vue";
import Top from "./Top.vue";
import Bottom from "./Bottom.vue";

export default Vue.extend({
  components: {
    Top,
    Bottom
  },

  mounted() {
    this.$store.dispatch("auth/changeTheme", "default");
  }
});
