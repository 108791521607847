<template>
  <div>
    <div v-if="!userSignedIn">
      <v-item-group class="d-none d-sm-block">
        <v-item class="mr-md-4">
          <v-btn rounded text @click="goTo({ name: 'signin' })">Giriş</v-btn>
        </v-item>
        <v-item>
          <v-btn
            rounded
            color="primary"
            dark
            large
            elevation="20"
            @click.prevent="showChat()"
            >Demo Talep Et</v-btn
          >
        </v-item>
      </v-item-group>

      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon color="primary" dark v-on="on" class="d-sm-none">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-card class="d-sm-none">
          <v-list nav dense>
            <v-list-item @click.prevent="goTo({ name: 'signin' })">
              <v-list-item-content>Giriş</v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="showChat()">
              <v-list-item-content>Demo Talep Et</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <div v-else-if="userSignedIn">
      <v-menu :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon color="primary" dark v-on="on" class="mr-5 mr-md-0 mt-1">
            <v-icon large>person</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list nav>
            <v-list-item>
              <v-list-item-avatar color="grey lighten-2">
                <v-icon>person</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-list dense nav>
            <v-list-item
              @click.prevent="goTo({ name: 'admin-home' })"
              v-if="this.user.roles.includes('admin')"
            >
              <v-list-item-icon>
                <v-icon>admin_panel_settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Yönetim Paneli</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click.prevent="goTo({ name: 'supervisor-home' })"
              v-if="this.user.roles.includes('supervisor')"
            >
              <v-list-item-icon>
                <v-icon>supervisor_account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Supervisor Paneli</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="goTo({ name: 'my-courses' })">
              <v-list-item-icon>
                <v-icon>dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Eğitimlerim</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click.prevent="goTo({ name: 'settings' })">
              <v-list-item-icon>
                <v-icon>settings</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Ayarlar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider />

          <v-list dense nav>
            <v-list-item @click.prevent="signOut()">
              <v-list-item-icon>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Çıkış</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    userSignedIn() {
      return this.$store.getters["auth/userSignedIn"];
    },
    user() {
      return this.$store.getters["auth/user"];
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path).catch();
    },
    signOut() {
      this.$store.dispatch("auth/signOut");
      // this.$router
      //   .replace({
      //     name: "home"
      //   })
      //   .catch();
    },
    showChat() {
      /* eslint-disable */
      Tawk_API.toggle();
      /* eslint-enable */
    }
  }
};
</script>
