<template>
  <div id="top">
    <v-container>
      <v-row no-gutters justify="space-between" class="mt-4">
        <router-link to="/">
          <v-img src="../../assets/img/utex-b2b-logo.png" width="234" />
        </router-link>
        <menu-top />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import MenuTop from "./MenuTop";

export default Vue.extend({
  components: {
    MenuTop
  }
});
</script>
